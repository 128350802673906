.idzDark{
    background: #252525;
}

.homepageIcon{
    width: 90%;
}

.homepageFontLarge{
    font-size: 1.5vw;
}

.idzLabel{
    font-size: 1.2vw;
}

.hidden{
    display: none;
}

/*  Learners  */




/*************/

.mainMenuFont{
    font-size: 1.5vw;
}

.homepageFontCount{
    font-size: 3vw;
}

.imgCursorOver{
    cursor: pointer;
}

.inlineBlock{
    display: inline-block;
}

.tblIcon{
    height: 0.9em;
}

.logOutBtn{
    height: 20px;
    cursor: pointer;
}

.loadingGif{
    height: 1.1em;
    opacity: 0.5;
}

 /* The Modal (background) */
 .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  }

  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    box-shadow: 3px 3px 5px #034591;
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .selectCustomClassInfo {
    background-image:
      linear-gradient(45deg, transparent 50%, #1898ae 60%),
      linear-gradient(135deg, #1898ae 40%, transparent 50%) !important;
    background-position:
      calc(100% - 30px) 14px,
      calc(100% - 20px) 14px,
      100% 0;
    background-size:
      10px 10px,
      10px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .tblFontSize{
      font-size: 1.2vw;
  }

  .tblFontSizeSmall{
    font-size: 1.0vw;
  }

  .accBlock{
      /*background: rgba(255, 255, 255, 0.082);*/
      border-radius: 10px;
      color: rgb(199, 199, 199);
      padding: 2px;
  }

  .accBlockNumber{
    /*background: rgba(255, 255, 255, 0.082);*/
    border-radius: 10px;
    text-align: right;
    color: rgb(199, 199, 199);
    padding: 2px;
}